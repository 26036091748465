.adjust-enquiry {
  height: fit-content;
  max-height: 40vh;
  overflow-y: scroll;
}

@media print {
  .hide-item-in-print {
    display: none;
  }
  .adjust-enquiry {
    height: fit-content;
    max-height: none;
  }
}
